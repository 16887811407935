<template>
  <div id="dashboard">
    <div class="row mb-2 justify-content-start">
        <div class="col-12 col-lg-8">
            <b-card
                sub-title="Get Started"
                :img-src="absoluteUrl('/images/banners/img05.jpg')"
                img-alt="Image"
                img-top
                tag="article"
                class="pt-2"
            >
                <b-card-text>
                    As a registered teacher, you are eligible to create a language group or join an existing language group
                </b-card-text>
            </b-card>
        </div>
         <div class="col-lg-4">
            <div class="row">
                <div class="col-6 col-lg-12 mb-lg-3">
                    <div class="card mb-0">
                        <div class="card-body">
                            <h4 class="mb-2 font-16 text-center">Groups created</h4>
                            <h2 class="m-0 text-center" id="active-views-count">{{result.groups_created}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-12">
                    <div class="card mb-0">
                        <div class="card-body">
                            <h4 class="mb-2 font-16 text-center">Groups joined</h4>
                            <h2 class="m-0 text-center" id="active-views-count">{{result.groups_joined}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-3 mt-lg-0 col-lg-8">
            <div style="">
                <router-link to="/languages?create=true" class="btn mr-3 btn-primary mb-2 px-3 py-2">Create a language group</router-link>
                <router-link to="/languages" class="btn btn-secondary mb-2 px-3 py-2" >Existing languages</router-link>
            </div>
        </div>
    </div>
    <div class="row mb-4 justify-content-start">
        <div class="col-12">
            <div class="card card-body">
                <h4 class="mb-3 text-uppercase">General Guidelines</h4>
                <group-guidelines @resetData="resetData()"/>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import GroupGuidelines from "@/components/group/GroupGuidelines.vue"

export default{
    components: {
        GroupGuidelines,
    },
    data() {
        return {
            result:{
                groups_joined: 0,
                groups_created: 0
            }
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    },
    created(){
        this.$http.get('/analytics')
        .then((response) => {
            if(response.data.success){
                this.result = response.data.data
            }
        })
    }
}
</script>
